

























































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import AlbumLimitHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/AlbumLimitHandlerModule/AlbumLimitHandlerModuleBuilder'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    SwitchInput,
    NumberInput,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    Icon
  },
})
export default class AlbumLimitHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: AlbumLimitHandlerModuleBuilder
}
